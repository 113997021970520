import React from "react";
import { HiMiniHome, HiOutlineDocument } from "react-icons/hi2";
import { useNavigate, useLocation, Outlet } from "react-router-dom";

const MainContent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Navigation functions
  const homeNav = () => {
    navigate("/user");
  };

  const handleTabChange = (tab) => {
    navigate(`/user/${tab}`);
  };

  // Determine the active tab based on the current location
  const isActive = (path) =>
    location.pathname === `/user${path === "home" ? "" : `/${path}`}`;

  return (
    <>
      <div className="d-flex bg-dark border-t-1 " >
        <div className="w-15 px-3 position-relative z-2 pt-5 text-black br-r border-b">
          {/* Home Tab */}
          <div
            onClick={homeNav}
            className={`d-flex align-items-center justify-content-start gap-lg-5 gap-0 pointer fs-5 user-side rounded mt-2 border border-1 ${
              isActive("home") ? "active-tab " : "text-white"
            }`}
          >
            <div className="p-3">
              <HiMiniHome
                className={`fs-4 `}
              />
            </div>
            <div className=" d-none d-sm-grid">
              <p className="pt-3">Home</p>
            </div>
          </div>

          {/* Files requests */}
          <div
            onClick={() => handleTabChange("files-requests")}
            className={`d-flex border align-items-center justify-content-start gap-xl-5 gap-lg-4 gap-0 pointer fs-5 user-side rounded mt-2 text-white ${
              isActive("files-requests") ? "active-tab " : "text-white"
            }`}
          >
            <div className="p-3">
              <HiOutlineDocument
                className={`fs-4 `}
              />
            </div>
            <div className=" d-none d-sm-grid">
              <p className={` pt-3 fs-6 `}>Files Requests</p>
            </div>
          </div>
        
        </div>

        <div className="main-content-body d-flex flex-column w-75" style={{ height: 'calc(100vh - 55px)', overflowY: 'scroll' }}>
        <Outlet />
        </div>
      </div>
    </>
  );
};

export default MainContent;
