import React, { useState, useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import CreatorHub from "./components/CreatorHub/CreatorHub";
import Login from "./components/Auth/Login";
import Signup from "./components/Auth/Signup";
import MainContentUser from "./components/MainContent/MainContentUser";
import MainContentAdmin from "./components/MainContent/MainContentAdmin";
import UploadFiles from "./components/MainContent/UploadFiles";
import UserFiles from "./components/MainContent/UserFiles";
import UsersFilesRequests from "./components/MainContent/UsersFilesRequests";
import UsersRequests from "./components/MainContent/UsersRequests";
import { HashRouter as BrowserRouter, Route, Routes } from "react-router-dom";

import ProtectedRoute from "./components/Auth/PrivateRoute";
import { auth, db } from "./firebase/firebase";
import Nav from "./components/CreatorHub/Navbar/Nav";
import { getDoc, doc } from "firebase/firestore";

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [userRole, setUserRole] = useState("");
  // Listen to authentication state changes
  useEffect(() => {
    const checkUserStatus = async (user) => {
      if (user) {
        setCurrentUser(user); // User is logged in
        try {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            if (userData.status === "accepted") {
              setUserRole(userData.role);
            } else {
              setUserRole(null);
            }
          }
        } catch (error) {
          console.error("Error fetching user data: ", error);
        }
      } else {
        setCurrentUser(null); // No user is logged in
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      checkUserStatus(user);
    });

    // Clean up the listener on component unmount
    return () => unsubscribe();
  }, []);

  return (
    <BrowserRouter>
      <Nav currentUser={currentUser} userRole={userRole} />
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<CreatorHub />} />
        <Route path="/login" element={<Login />} />
        <Route path="/sign-up" element={<Signup />} />

        {/* Protected Routes */}
        <Route
          path="/user"
          element={
            <ProtectedRoute>
              {userRole && userRole === "admin" ? (
                <MainContentAdmin currentUser={currentUser} />
              ) : (
                <MainContentUser currentUser={currentUser} />
              )}
            </ProtectedRoute>
          }
        >
          {userRole && userRole === "admin" ? (
            <>
              <Route
                path="/user"
                element={
                  <UsersRequests
                    currentUser={currentUser}
                    userRole={userRole}
                  />
                }
              />
              <Route
                path="files-requests"
                element={
                  <UsersFilesRequests
                    currentUser={currentUser}
                    userRole={userRole}
                  />
                }
              />
            </>
          ) : (
            <>
              <Route
                path="/user"
                element={
                  <UserFiles currentUser={currentUser} userRole={userRole} />
                }
              />
              <Route
                path="submit-insert"
                element={
                  <UploadFiles currentUser={currentUser} userRole={userRole} />
                }
              />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
