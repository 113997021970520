import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";  
// import { setLogLevel } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCPiJWfwW8aCvScXQy_rgHbvOiOPkBu-zg",
  authDomain: "miinserts-d4e63.firebaseapp.com",
  projectId: "miinserts-d4e63",
  storageBucket: "miinserts-d4e63.appspot.com",
  messagingSenderId: "701397370339",
  appId: "1:701397370339:web:d1879fbebf7c783faa8cd6",
  measurementId: "G-2L4BS4K8RG"
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);  

// Enable debugging
// setLogLevel("debug");

export { auth, db, storage };
