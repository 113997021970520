import React, { useState } from "react";
// import roblox from "./images/roblox.svg";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../firebase/firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { Toast } from "react-bootstrap";
import LoadingSpinner from "../LoadingSpinner";
function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [robloxUserID, setRobloxUserID] = useState("");
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({ show: false, message: "", variant: "" });
  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      await setDoc(doc(db, "users", user.uid), {
        email: email,
        robloxUserID: robloxUserID,
        status: "pending",
        role: "user",
      });

      setToast({
        show: true,
        message: "User signed up successfully!",
        variant: "success",
      });
      setTimeout(() => navigate("/login"), 2000); // Redirect to login page after 2 seconds
    } catch (error) {
      console.error("Error signing up:", error.code, error.message);
      setToast({
        show: true,
        message: `Error signing up: ${error.message}`,
        variant: "danger",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-100  overflow-hidden">
      <div className="d-grid align-items-center  w-100 background2 position-relative  ">
        <div className="overlay position-absolute w-100 "></div>

        {/* Content Area */}
        <div className="content position-absolute top-0 text-white w-100  ">
          <div className="text-center ps-8 ">
            <div className="row  justify-content-end">
              {/* <div className="col-sm-4 col-12">
                <img src={roblox} alt="roblox" className="roblox-logo-image" />
              </div> */}
              <div className="col-sm-4 col-12 text-end prs-8  ">
                <div className="w-100 d-flex justify-content-sm-end justify-content-center ">
                  {/* <Link to="/login" className="login-roblox rounded">
                    Log In
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-35 rounded  ">
          {/* Correctly handle form submission */}
          <form onSubmit={handleSignUp}>
            <div className="d-grid justify-content-center ">
              <h1 className="sign-in-text tex-center">
                Sign Up to Submit Inserts!
              </h1>
              <label className="pt-4 pb-2">Roblox User ID</label>
              <input
                type="text"
                required
                className="bg-black text-white py-1 px-2 border border-1 rounded"
                placeholder="Roblox User ID"
                onChange={(e) => setRobloxUserID(e.target.value)}
              />
              <label className="pt-4 pb-2">Email</label>
              <input
                type="email"
                required
                className="bg-black text-white py-1 px-2 border border-1 rounded"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <label className="pt-4 pb-2">Password</label>
              <input
                type="password"
                required
                className="bg-black text-white py-1 px-2 border border-1 rounded"
                placeholder="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <p className="fs-7 pt-2">
                By clicking Sign Up, you are agreeing to the{" "}
                <a href="https://mountaininteractive.com/terms/" target="_blank" className="text-info line">
                  Terms of Use
                </a>{" "}
                including <br /> the arbitration clause and you are acknowledging the{" "}
                <a href="https://mountaininteractive.com/privacy/" target="_blank" className="text-info line">
                  Privacy Policy
                </a>
              </p>
              <button
                type="submit"
                className="btn btn-secondary text-dark fw-medium"
                disabled={loading}
              >
                {loading ? <LoadingSpinner /> : "Sign Up"}
              </button>
            </div>
          </form>
        </div>

        {/* Bootstrap Toasts */}
        <Toast
          show={toast.show}
          onClose={() => setToast({ ...toast, show: false })}
          bg={toast.variant}
          className="position-fixed bottom-0 end-0 m-3"
        >
          <Toast.Body>{toast.message}</Toast.Body>
        </Toast>
      </div>
    </div>
  );
}

export default SignUp;
