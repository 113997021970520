import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { storage, db } from "../../firebase/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore";

const ManageIds = ({ currentUser }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const uid = currentUser && currentUser ? currentUser.uid : null;

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

    if (fileExtension !== "rbxm") {
      setError("Only .rbxm files are allowed.");
      setFile(null); // reset file selection
    } else {
      setError(""); // clear any previous error
      setFile(selectedFile); // set the selected file
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!uid) {
      setError("User is not authenticated.");
      return;
    }
    if (!file || !title || !description) {
      setError("All fields are required!");
      return;
    }
    setError("");
    setLoading(true);

    // Define storage path
    const storageRef = ref(storage, `uploads/${uid}/${file.name}`);

    try {
      // Upload file to Firebase storage
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
          console.error("Upload error:", error);
          setError("File upload failed. Please try again.");
          setLoading(false);
        },
        async () => {
          try {
            // Get file download URL on successful upload
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

            // Save file metadata to Firestore
            await addDoc(collection(db, "users", uid, "files"), {
              title,
              description,
              fileURL: downloadURL,
              status: "pending",
              timestamp: new Date(),
            });

            setSuccess("File uploaded successfully!");
            setTitle("");
            setDescription("");
            setFile(null);
          } catch (err) {
            console.error("Firestore error:", err);
            setError("Failed to save file metadata. Please try again.");
          } finally {
            setLoading(false);
          }
        }
      );
    } catch (err) {
      console.error("Error:", err);
      setError("File upload failed. Please try again.");
      setLoading(false);
    }
  };

  return (
    <div className="flexC w-100 text-white">
      <Form onSubmit={handleSubmit} className="ms-auto w-100 px-md-5 px-2 py-4">
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}

        <Form.Group controlId="fileTitle" className="">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Enter title"
            required
            className=""
          />
        </Form.Group>

        <Form.Group controlId="fileDescription">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter description"
            required
          />
        </Form.Group>

        <Form.Group controlId="fileUpload">
          <Form.Label>Upload File</Form.Label>
          <Form.Control type="file" onChange={handleFileChange} required />
        </Form.Group>

        <Form.Group controlId="formDisclaimer">
          <Form.Text className="text-white">
            Only .rbxm files are allowed for upload.
          </Form.Text>
        </Form.Group>

        <Button variant="primary" type="submit" disabled={loading}>
          {loading ? "Uploading..." : "Submit"}
        </Button>
      </Form>
    </div>
  );
};

export default ManageIds;
