import React, { useState, useRef, useEffect } from "react";
import logo from "../images/ourlogo.png";
import { MdArrowDropDown } from "react-icons/md";
import { FaBars } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import userIcon from "../images/user.webp";
import { auth, db } from "../../../firebase/firebase";
import { getDoc, doc } from "firebase/firestore";

const Nav = ({ currentUser }) => {
  const [active, setActive] = useState(false);
  const [userActive, setUserActive] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  // const asideRef = useRef(null);

  const setting = [{ list: "Logout" }];
  const navTexts = [{ text: "Home" }];

  const ToggleFunction = () => {
    setActive(!active);
  };

  const ToggleDropDown = () => {
    setUserActive(!userActive);
  };

  useEffect(() => {
    const checkUserStatus = async (user) => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setIsApproved(userData.status === "accepted");
          }
        } catch (error) {
          console.error("Error fetching user data: ", error);
        }
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      checkUserStatus(user);
    });

    return () => unsubscribe();
  }, []);

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) 
        
        // !asideRef.current.contains(event.target)
      ) {
        setUserActive(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav className="bg-dark h-60 position-relative" style={{ width: "100%" }}>
      <div className="d-flex h-100 align-items-center justify-content-between custom-padding">
        <div className="d-md-none">
          <FaBars onClick={ToggleFunction} className="icon-bars pointer" />
        </div>

        <div className="d-md-flex align-items-center gap-5 d-none">
          <Link
            to="/"
            className="d-flex align-items-center gap-3 text-decoration-none"
          >
            <img src={logo} className="w-21" alt="logo" />
            <span className="regular-font" style={{ fontSize: "20px" }}>
              Mountain Insert Hub
            </span>
          </Link>

          <div className="d-flex gap-4">
            {navTexts.map((value, index) => (
              <Link key={index} to="/" className="text-decoration-none">
                {value.text}
              </Link>
            ))}
          </div>
        </div>

        <div className="d-flex align-items-center " 
              >
          {currentUser && currentUser.email && isApproved ? (
            <div
              className="d-flex align-items-center"
              onClick={ToggleDropDown}
              ref={dropdownRef}
            >
              <div
                className="d-flex align-items-center"
                style={{ cursor: "pointer" }}

              >
                <h1 className="fs-5 pt-1">
                  {currentUser.displayName || currentUser.email}
                </h1>
                <MdArrowDropDown className="icon-down" aria-label="Dropdown icon" />
              </div>
              {userActive && (
                <div className="position-absolute top-100 user-dropdown rounded toggle h-100">
                  <ul className="list-unstyled h-100">
                    {setting.map((x, i) => (
                      <li key={i} className="h-100">
                        <div
                          onClick={(event) => {
                            event.preventDefault();
                            if (x.list === "Logout") {
                              setIsApproved(false);
                              auth.signOut();
                              navigate("/login");
                            }
                          }}
                          className="text-decoration-none list-color-user h-100 flexC rounded pointer"
                        >
                          {x.list}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ) : (
            <>
              <div className="d-flex align-items-center login">
                <Link to="/sign-up" className="text-decoration-none">
                  <h1 className="font-20 text-white">Register</h1>
                </Link>
              </div>
              <div className="d-flex align-items-center login">
                <Link to="/login" className="text-decoration-none">
                  <h1 className="font-20 text-white">Login</h1>
                </Link>
              </div>
            </>
          )}
        </div>
      </div>

      {/* Mobile menu (aside) */}
      {active && <aside
        className={`aside position-absolute z-index  top-0 d-md-none ${active ? "aside-open" : "aside-closed"}`}
        style={{
          height: "100vh",
          borderRight: "1px solid rgb(90, 88, 88)"
        }}
      >
        <div className="h-60-aside w-100">
          <div className="d-flex align-items-center h-100 justify-content-between aside-padding">
            <div className="d-flex align-items-center gap-3">
              <img src={logo} className="aside-logo" alt="logo" />
              <span className="regular-font font-24-aside">Creator Hub</span>
            </div>
            <div>
              <IoMdClose onClick={ToggleFunction} className="close" aria-label="Close menu" />
            </div>
          </div>
        </div>
        <div className="d-grid aside-nav-texts">
          {navTexts.map((value, index) => (
            <div key={index} className="inner-aside">
              <Link to="/" className="text-decoration-none aside-text">
                {value.text}
              </Link>
            </div>
          ))}
        </div>
      </aside>}
    </nav>
  );
};

export default Nav;
